<template>
	<div>
		<div style="position: absolute;left: 0;top: 0;width: 100vw;height: 100%;display: flex;flex-direction: column;">
			<!-- 上面图片 -->
			<div><img style="width: 100%;margin: -108px;" src="/蓝绿粉色中国风廉洁文化微信公众号封面.jpg" @click="goToHome"></div>
			<!-- 内容 -->
			<div style="flex: 1;background-image: url('/蓝绿粉色中国风廉洁文化微信公众号封面1.jpg');background-size: cover;">
				<MENU></MENU>
				<el-carousel :interval="4000" type="card" height="300px">
					<el-carousel-item v-for="image in images" :key="image.id">
						<img :src="image.url" alt="" class="carousel-img" />
					</el-carousel-item>
				</el-carousel>
				<div style="display: flex; height: 650px; padding-left: 15%;">
					<div style="flex: 1; max-width: 900px;">
						<el-carousel height="580px" direction="vertical" :interval="4000">
							<el-carousel-item v-for="image in img" :key="image.id">
								<img :src="image.url" alt="" class="carousel-img" />
							</el-carousel-item>
						</el-carousel>
					</div>
					<div style="flex: 0 0 auto; margin-left: 20px; padding-top: 80px;">
						<el-timeline>
							<el-timeline-item timestamp="2024-11-04" placement="top">
								<el-card>
									<h4 @click="go5">{{ link5 }}</h4>
								</el-card>
							</el-timeline-item>
							<el-timeline-item timestamp="2024-08-16" placement="top">
								<el-card>
									<h4 @click="go4">{{ link4 }}</h4>
								</el-card>
							</el-timeline-item>
							<el-timeline-item timestamp="2024-07-18" placement="top">
								<el-card>
									<h4 @click="go3">{{ link3 }}</h4>
								</el-card>
							</el-timeline-item>
							<el-timeline-item timestamp="2024-06-20" placement="top">
								<el-card>
									<h4 @click="go2">{{ link2 }}</h4>
								</el-card>
							</el-timeline-item>
							<el-timeline-item timestamp="2024-04-30" placement="top">
								<el-card>
									<h4 @click="go1">{{ link1 }}</h4>
								</el-card>
							</el-timeline-item>
						</el-timeline>
					</div>
				</div>
				<div class="footer">
					<div class="footer-content">
						<div class="footer-section">
							<h3>校区地址</h3>
							<p>关山校区：湖北省武汉市东湖高新区光谷一路206号</p>
							<p>鲁巷校区：湖北省武汉市洪山区珞瑜路1037号</p>
						</div>
						<div class="footer-section">
							<h3>联系方式</h3>
							<p>招生电话：027-87588821</p>
							<p>书记校长信箱 接访电话：027-87588771</p>
							<p>邮箱：hkzjyk@126.com</p>
						</div>
						<div class="footer-section">
							<h3>其他链接</h3>
							<p>
								<a href="https://www.hubstc.com.cn/" target="_blank" rel="noopener noreferrer">
									官网链接（点击跳转）
								</a>
							</p>
							<p>
								<a href="https://www.hbou.edu.cn/" target="_blank" rel="noopener noreferrer">
									开放大学
								</a>
							</p>
						</div>
						<div class="footer-section copyright">
							<p>&copy; 2024 湖北科技职业学院. 版权所有.</p>
							<p>鄂ICP备11015833号-2 </p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import MENU from './MENU.vue';
export default {
	name: 'Home',
	components: {
		MENU
	},
	data() {
		return {
			images: [
				{ id: 1, url: require('/public/1.jpg') },
				{ id: 2, url: require('/public/2.jpg') },
				{ id: 3, url: require('/public/3.jpg') },
				{ id: 4, url: require('/public/4.jpg') }
			],
			img: [
				{ id: 1, url: require('../../public/1.png') },
				{ id: 2, url: require('../../public/2.png') }
			],
			link1: "【清廉校园】学校召开采购项目校内评委专题培训会",
			link2: "校团委举办“青廉画·清廉与青春同行”廉洁文化书画作品展活动",
			link3: "软件工程学院多形式开展廉洁教育",
			link4: "校纪委、工会联合开展廉政家访活动",
			link5: "省纪委监委刊物《楚天风纪》刊发我校清廉学校建设经验做法",
		}
	},
	methods: {
		handleSelect(key, keyPath) {
			console.log(key, keyPath);
		},
		goToHome() {
			this.$router.push('/')
		},
		generateLink(row) {
			return `${row.to}`;
		},
		go1() {
			window.location.href = 'https://www.hubstc.com.cn/info/1070/13056.htm';
		},
		go2() {
			window.location.href = 'https://www.hubstc.com.cn/info/1070/13245.htm';
		},
		go3() {
			window.location.href = 'https://www.hubstc.com.cn/info/1070/13309.htm';
		},
		go4() {
			window.location.href = 'https://www.hubstc.com.cn/info/1070/13364.htm';
		},
		go5() {
			window.location.href = 'https://www.hubstc.com.cn/info/1068/13618.htm';
		}
	}
};
</script>

<style scoped>
* {
	padding: 0;
	margin: 0;
}

.carousel-img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.footer {
  background-color: rgb(206,223,226); 
  padding: 20px 0; /* 内边距 */
  text-align: center; /* 文字居中 */
  color: #333; /* 文字颜色 */
  font-size: 14px; /* 文字大小 */
}
 
.footer-content {
  display: flex; /* 使用 flex 布局 */
  flex-wrap: wrap; /* 允许换行 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中（在这个上下文中可能不太明显） */
  max-width: 1200px; /* 最大宽度 */
  margin: 0 auto; /* 水平居中 */
}
 
.footer-section {
  flex: 1; /* 等宽布局 */
  min-width: 200px; /* 最小宽度 */
  padding: 10px; /* 内边距 */
  box-sizing: border-box; /* 包括内边距和边框在内计算宽度 */
}
 
.footer-section h3 {
  margin-top: 0; /* 去掉上边距 */
  font-size: 16px; /* 标题大小 */
  font-weight: bold; /* 加粗 */
}
 
.footer-section p {
  margin: 5px 0; /* 段落上下边距 */
}
 
.footer-section a {
  color: #007bff; /* 链接颜色 */
  text-decoration: none; /* 去掉下划线 */
}
 
.footer-section a:hover {
  text-decoration: underline; /* 悬停时显示下划线 */
}
 
.copyright {
  width: 100%; /* 全宽 */
  margin-top: 20px; /* 上边距 */
  border-top: 1px solid #ddd; /* 上边框 */
  padding-top: 10px; /* 上内边距 */
  font-size: 12px; /* 文字大小 */
}
</style>